.course-wrapper {
  background-image: url('../../assets/images/bodyBG-whitened.jpg');
  background-size: contain;
}

.course-preview-main {
  object-fit: cover;
}

.course-img {
  width: 532px;
  height: 300px;
}

.course-preview-main {
  width: 711px;
  height: 400px;
}

.price-indicator {
  transform: translateY(7px);
}

.course-description {
  gap: 3rem;
  margin-bottom: 100px;
}

.buy-btn {
  scale: 0.85;
}

@media only screen and (max-device-width: 1350px) {
  .course-description {
    flex-direction: column;
  }
  .course-about {
    margin-top: 2rem;
  }
}

@media only screen and (max-device-width: 1200px) {
  .course-descr-section {
    flex-direction: column;
  }
}
