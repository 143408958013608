@font-face {
  font-family: Drum;
  src: url(../../assets/fonts/Drum.ttf);
}

@font-face {
  font-family: Besom;
  src: url(../../assets/fonts/Besom.ttf);
}

@font-face {
  font-family: Hey October;
  src: url(../../assets/fonts/HeyOctober.ttf);
}

.title {
  font-family: Drum;
}

/* haircutsSection */
.haircuts-section {
  padding-top: 140px;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 100px;
}

/* title */
.title {
  margin: 0;
  color: #fff;
  font-weight: 100;
  font-size: 70px;
  margin-top: -24px;
}

/* cards */
.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;

  row-gap: 5rem;
}

.courses-header {
  font-size: 70px;
  font-weight: 500;
}

@media (max-width: 900px) {
  .cards {
    justify-content: center;
    row-gap: 3rem;
  }
  h1 {
    font-size: 50px;
    text-align: center;
  }
  .haircuts-section {
    padding-top: 60px;
  }
}
