.signin-wrapper {
  background-image: url('../../assets/images/bodyBG.webp');
  background-size: contain;
}

.email-input,
.password-input,
.name-input {
  background-image: url('../../assets/images/inputBorder.png');
  background-position: right;
  background-size: cover;
  outline: 0;
  padding-left: 10px;
  margin-left: -1px;
}

.submit-btn {
  transition: all 0.3s ease;
}

.submit-btn:hover {
  transform: scale(1.05);
  transition-duration: 300ms;
}

@media (max-width: 520px) {
  .signin-wrapper {
    /* display: none !important; */
    width: 90% !important;
  }
  .signupwr {
    display: none !important;
  }
  .signup-offer {
    gap: 2px !important;
    justify-content: center;
  }
  .signinMob p {
    /* width: 70% !important; */
    white-space: nowrap;
    /* font-size: 11px; */
    padding-right: 10px !important;
  }
  .signup-offer button {
    font-size: 15px;
    white-space: nowrap;
  }
  .email-input,
  .password-input {
    background-image: url(../../assets/images/signinputmob.png);
    width: 98% !important;
  }
  .signin-with h4 {
    white-space: nowrap;
    font-size: 19px !important;
  }
  .submit-section {
    flex-direction: column;
    justify-content: center !important;
  }
}
