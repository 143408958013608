.btnContainer {
  position: relative;
  display: block;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.strokeBtnText {
  position: absolute;
  transform: translate(-50%, -50%);
  /* font-size: 50px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* color: rgb(200, 200, 200); */
  font-family: 'Drum';
  transform-origin: center;
}

.btnStrokes {
  position: absolute;
  overflow: visible;
}

#t,
#l,
#r,
#b,
.strokeBtnText {
  transition: all 0.2s ease;
}

.btnContainer {
  overflow: visible;
}

.btnContainer:hover #t {
  transform: translateY(-10%);
}
.btnContainer:hover #b {
  transform: translateY(10%);
}
.btnContainer:hover #l {
  transform: translateX(-10%);
}
.btnContainer:hover #r {
  transform: translateX(10%);
}

.button1 {
  font-size: 50px;
}
.btnContainer:hover .button1 {
  font-size: 60px;
}
.button2 {
  font-size: 60px;
}
.btnContainer:hover .button2 {
  font-size: 70px;
}
.button3 {
  font-size: 71px;
}
.btnContainer:hover .button3 {
  font-size: 80px;
}
.button4 {
  font-size: 27px;
}
.btnContainer:hover .button4 {
  font-size: 36px;
}
.button5 {
  font-size: 26px;
}
.btnContainer:hover .button5 {
  font-size: 30px;
}
