.view-all-courses {
  scale: 0.9;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.btnContainer {
  flex-shrink: 0;
}

@media screen and (max-width: 800px) {
  .view-all-courses {
    scale: 0.7;
  }
}
@media screen and (max-width: 650px) {
  .view-all-courses {
    scale: 0.6;
  }
}
@media screen and (max-width: 550px) {
  .view-all-courses {
    scale: 0.5;
  }
}
@media screen and (max-width: 350px) {
  .view-all-courses {
    scale: 0.4;
  }
}
