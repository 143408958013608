.changePass-wrapper {
  background-image: url('../../assets/images/bodyBG.webp');
  background-size: contain;
}

.emailCode-input {
  outline: 0;
  background-image: url('../../assets/images/emailCodeInp.png');
}

.passEmailInput {
  outline: 0;
  background-image: url('../../assets/images/emailCodeInp.png');
  background-size: contain;
  background-repeat: no-repeat;
}
