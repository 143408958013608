.barber-pfp {
  width: 400px;
}
.pfp {
  display: flex;
  justify-content: center;
}

.whymeP {
  margin: 0;
  font-family: 'Drum';
  font-size: 110px;
  padding-bottom: 20px;
}

.pStyle {
  margin: 15px 0;
  font-family: 'Drum';
  font-size: 50px;
}

.pStyle::before {
  content: '-';
  position: absolute;
  margin-left: -40px;
}
@media (max-width: 1000px) {
  .whymeP {
    font-size: 60px;
  }
  .pStyle {
    font-size: 30px;
  }
}
@media (max-width: 500px) {
  .whyme {
    display: none !important;
  }
  .barber-pfp {
    width: 80%;
  }
  .underpfp {
    padding-top: 0px !important;
  }
}

@media (min-width: 1007px) {
  .pfp {
    height: 600px;
  }
}
