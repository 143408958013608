.logo {
  background-image: url('../../assets/images/skull_logo.svg');
  width: 110px;
  height: 60px;
  background-repeat: no-repeat;
}

.navbar-equal-item {
  flex: 1 1 0px;
  display: flex;
}

.navbar-left {
  justify-content: start;
}
.navbar-center {
  justify-content: center;
}
.tabs {
  justify-content: end;
}

@media (max-width: 910px) {
  .title-logo {
    display: none !important;
  }
  .logo {
    width: 73px;
    height: 40px;
  }
}

@media (max-width: 460px) {
  .aboutme,
  .courses,
  .signin-btn {
    font-size: 14px !important;
  }

  .aboutme-wrapper,
  .courses-wrapper,
  .signin-navbar-wrapper {
    padding: 0px 15px !important;
    white-space: 'nowrap';
  }
}

/* @media (max-width: 500px) {
  .account-btn {
    display: flex !important;
  }
  .signin-btn {
    display: none !important;
  }
} */
