.offer-preview-card {
  object-fit: cover;
  height: 200px;
}
.card_heading {
  font-weight: 800;
}

@media (max-width: 1335px) {
  .card-wrapper {
    height: 300px !important;
    width: 290px !important;
  }
}

@media (max-width: 900px) {
  .card-wrapper {
    height: 300px !important;
    width: 290px !important;
  }
  .card-1 {
    top: 7px !important;
    left: -100px !important;
    transform: rotate(-19deg) !important;
  }
  .card-3 {
    left: 100px !important;
    transform: rotate(19deg) !important;
  }
}

@media (max-width: 580px) {
  .offer-preview-card {
    height: 140px;
  }
  .card_heading {
    margin: 10px 0px 0px !important;
    padding-left: 5px !important;
    font-size: 15px !important;
    font-weight: 500;
  }
  .card_description {
    font-size: 14px;
  }
  .card_description p {
    margin: 0px;
  }
  .card-wrapper {
    height: 235px !important;
    width: 175px !important;
  }
  .card-1 {
    left: -46px !important;
    transform: rotate(-19deg) !important;
  }
  .card-3 {
    left: 49px !important;
    transform: rotate(19deg) !important;
  }
}
