.buy-btn {
  transition: all 0.3s ease;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.mini-btn {
  scale: 0.7;
  margin-left: -90px;
}

.buy-btn:hover {
  transform: scale(1.05);
  transition-duration: 300ms;
}

@font-face {
  font-family: Drum;
  src: url(../../assets/fonts/Drum.ttf);
}

@font-face {
  font-family: Besom;
  src: url(../../assets/fonts/Besom.ttf);
}

@font-face {
  font-family: Hey October;
  src: url(../../assets/fonts/HeyOctober.ttf);
}

.uah-img {
  position: relative;
}

.price {
  font-family: Hey October;
  text-decoration-thickness: 3px;
  position: absolute;
  display: flex;

  left: 377px;
  bottom: -11px;
}

.prev-price {
  font-family: Hey October;
  text-decoration: line-through;
  text-decoration-thickness: 3px;
  color: #979797 !important;
  position: absolute;
  display: flex;
  right: -30px;
  top: -40px;
  font-size: 60px !important;
}

.descr-2 {
  margin-right: 0 !important;
  margin-left: -100px !important;
}

.title {
  font-family: Besom;
}

.description-wrapper {
  font-family: Drum;
}

@media (max-width: 1070px) {
  .description-wrapper {
    margin-left: 60px;
  }
  .descr-2 {
    margin-left: 0px !important;
  }
}

@media (max-width: 1000px) {
  .description-wrapper {
    margin-left: 90px;
  }
  .descr-2 {
    margin-left: 0px !important;
  }
}

@media (max-width: 961px) {
  .description-wrapper {
    margin-left: 110px;
  }
  .descr-2 {
    margin-left: 0px !important;
  }
}

@media (max-width: 1400px) {
  .offer-wrapper {
    display: none !important;
  }
  .divider {
    display: none !important;
  }
}

@media (max-width: 1071px) {
  .card-1 {
    left: -67px !important;
  }
  .card-3 {
    left: 67px !important;
  }
}
