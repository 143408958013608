.signup-wrapper-mobile {
  background-image: url('../../assets/images/bodyBG.webp');
  background-size: contain;
}

.name-input-mobile {
  background-image: url('../../assets/images/nameinputMobile.png');
}

.surname-input-mobile,
.email-input-mobile {
  background-image: url('../../assets/images/nameinputMobile.png');
}

.password-input-mobile {
  background-image: url('../../assets/images/surnameinputMobile.png');
}

.email-input-mobile,
.password-input-mobile,
.name-input-mobile {
  background-size: cover;
  background-position: center;
  outline: 0;
  padding-left: 11px;
  margin-left: 3px;
}

.email-input-mobile {
  margin-left: 6px;
  margin-right: -2px;
}

.password-input-mobile {
  margin-left: 6px;
  margin-right: -1.9px;
}

/* .name-input,
.surname-input {
  background-image: url('../../assets/images/nameInputBorder.png');
  background-size: cover;
  background-position: center;
  outline: 0;
  padding-left: 10px;
  margin-left: -2px;
  margin-top: 4px;
} */

.surname-input {
  margin-left: -4px !important;
}

@media (max-width: 520px) {
  .signup-wrapper-mobile {
    display: flex !important;
  }
  .submitMob {
    flex-direction: column;
  }
}
