.top-section {
  background-position: top;
  height: 950px;
}

.buttonsWrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  gap: 30px;
}

.btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@media (max-width: 750px) {
  .top-section {
    background-position: top;
    height: 600px;
  }
  .buttonsWrapper {
    scale: 0.7;
    position: relative;
    transform: translate(-50px, 100px);
  }
}
