body {
  margin: 0;

  background-image: url('./assets/images/coursesBG.webp');
  width: -webkit-fill-available;
  position: relative;
  overflow-x: hidden;
  background-size: cover;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(35, 35, 35);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7a7a7a;
  border-radius: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  padding: 0px;
}
