@media (max-width: 900px) {
  .offer-wrapper-mobile {
    display: flex;
  }
  .price {
    bottom: -40px;
    left: -16px;
  }
}

@media (min-width: 1400px) {
  .divider-mobile {
    display: none !important;
  }
  .offer-wrapper-mobile {
    display: none;
  }
}

@media (max-width: 460px) {
  .price {
    margin-left: -34px !important;
    font-size: 48px !important;
  }
  .card_img {
    height: 41px;
    padding-top: 7.5px !important;
  }

  .card_btn {
    width: 175px !important;
  }
  .description-wrapper-mob p {
    right: 0% !important;
  }
}

.prev-price-mobile {
  font-family: Hey October;
  text-decoration: line-through;
  text-decoration-thickness: 3px;
  color: #979797 !important;
  position: absolute;
  right: -30px;
  top: -20px;
  font-size: 35px !important;
}

@media (max-width: 520px) {
  .card-1 {
    left: -53px !important;
  }
  .card-3 {
    left: 53px !important;
  }
}

/* @media (max-width: 1180px) {
  .offer-wrapper {
    gap: 3% !important;
  }
} */
