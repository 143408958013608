/* @media (max-width: 1489px) {
  .haircutWrap {
    width: 50% !important;
  }
} */

.haircut-preview {
  object-fit: cover;
}

.card {
  color: rgb(18, 18, 18);
}

.card:visited {
  color: rgb(18, 18, 18);
}
